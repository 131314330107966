.alert-container {
  display: flex;
  position: absolute;
  right: 20px;
  top: 80px;
  z-index: 1000;
}
.location-user-edit-delete-btn {
  font-size: 20px;
  outline: none !important;
}

.navbar.navbar-transparent {
  border-bottom: 0px !important;
}

.search-icon-button {
  margin-top: 25px;
  padding: 9px 20px;
  font-size: 1rem;
}

.add-user-button {
  margin-top: 25px;
}

.form-select__control {
  height: 40px;
}
